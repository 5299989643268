import { put, call } from 'redux-saga/effects'
import ScheduleMessageRuleActions from '../redux/ScheduleMessageRuleRedux'

export function * getScheduleMessageRules (api) {
  const resp = yield call(api.getScheduleMessageRules)

  if (resp.ok) {
    yield put(ScheduleMessageRuleActions.getScheduleMessageRulesSuccess(resp.data))
  } else {
    yield put(ScheduleMessageRuleActions.getScheduleMessageRulesFailure(resp.data))
  }
}

export function * createScheduleMessageRule (api, action) {
  const { scheduleMessageRule } = action

  const resp = yield call(api.createScheduleMessageRule, scheduleMessageRule)

  if (resp.ok) {
    yield put(ScheduleMessageRuleActions.createScheduleMessageRuleSuccess(resp.data))
  } else {
    yield put(ScheduleMessageRuleActions.createScheduleMessageRuleFailure(resp.data))
  }
}

export function * updateScheduleMessageRule (api, action) {
  const { scheduleMessageRule } = action

  const resp = yield call(api.updateScheduleMessageRule, scheduleMessageRule)

  if (resp.ok) {
    yield put(ScheduleMessageRuleActions.updateScheduleMessageRuleSuccess(resp.data))
  } else {
    yield put(ScheduleMessageRuleActions.updateScheduleMessageRuleFailure(resp.data))
  }
}

export function * destroyScheduleMessageRule (api, action) {
  const { scheduleMessageRule } = action

  const resp = yield call(api.destroyScheduleMessageRule, scheduleMessageRule)

  if (resp.ok) {
    yield put(ScheduleMessageRuleActions.destroyScheduleMessageRuleSuccess(resp.data))
  } else {
    yield put(ScheduleMessageRuleActions.destroyScheduleMessageRuleFailure(resp.data))
  }
}