import { createReducer, createActions } from 'reduxsauce'

import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getRoomsRequest: ['branchId'],
  getRoomsSuccess: ['rooms'],
  getRoomsFailure: ['errors'],

  getRoomRequest: ['roomId'],
  getRoomSuccess: ['room'],
  getRoomFailure: ['errors'],

  createRoomRequest: ['room'],
  createRoomSuccess: ['room'],
  createRoomFailure: ['errors'],

  updateRoomRequest: ['room'],
  updateRoomSuccess: ['room'],
  updateRoomFailure: ['errors'],

  destroyRoomRequest: ['room'],
  destroyRoomSuccess: ['room'],
  destroyRoomFailure: ['errors'],

  toggleRoomForm: ['isRoomFormVisible', 'editRoom'],
  clearRoom: null,

  requestStreamInfoRequest: ['roomStreamId'],
  requestStreamInfoSuccess: ['roomStream'],
  requestStreamInfoFailure: ['errors'],

  createStreamRequest: ['roomId'],
  createStreamSuccess: ['roomStream'],
  createStreamFailure: ['errors'],

  destroyStreamRequest: ['roomStreamId'],
  destroyStreamSuccess: ['roomStream'],
  destroyStreamFailure: ['errors']
})

export const RoomTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  room: null,
  errors: [],
  creatingRoom: false,
  fetching: false,
  rooms: [],
  updatingRoom: false,
  isRoomFormVisible: false,
  editRoom: null,
  destroyingRoom: false
})

/* ------------- Reducers ------------- */

export const getRoomsRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getRoomsSuccess = (state: Object, { rooms }: Object) => 
  state.merge({
    rooms,
    fetching: false
  })

export const getRoomsFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const getRoomRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getRoomSuccess = (state: Object, { room }: Object) => 
  state.merge({
    room,
    fetching: false
  })

export const getRoomFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const createRoomRequest = (state: Object) =>
  state.merge({
    creatingRoom: true,
    errors: []  
  })

export const createRoomSuccess = (state: Object, { room }: Object) => 
  state.merge({
    rooms: [...state.rooms, room],
    creatingRoom: false,
    isRoomFormVisible: false,
    editRoom: null
  })

export const createRoomFailure = (state: Object, { errors }: Object) =>
  state.merge({
    creatingRoom: false,
    errors
  })

export const updateRoomRequest = (state: Object) =>
  state.merge({
    updatingRoom: true,
    errors: []
  })

export const updateRoomSuccess = (state: Object, { room }: Object) => {
  let rooms = state.rooms
  
  rooms = rooms.map((w) => {
    if (w.id === room.id) {
      return room
    }

    return w
  })

  return state.merge({ 
    updatingRoom: false,
    isRoomFormVisible: false,
    rooms,
    editRoom: null
  })
}

export const updateRoomFailure = (state: Object, { errors }: Object) =>
  state.merge({
    updatingRoom: false,
    errors
  })

export const destroyRoomRequest = (state: Object) => 
  state.merge({ 
    destroyingRoom: true,
    errors: [] 
  })

export const destroyRoomSuccess = (state: Object, { room }: Object) =>
  state.merge({ 
    destroyingRoom: false,
    rooms: state.rooms.filter((w) => w.id !== room.id)
  })
  
export const destroyRoomFailure = (state: Object, { errors }: Object) =>
  state.merge({
    destroyingRoom: false, 
    errors 
  })

export const toggleRoomForm = (state: Object, { isRoomFormVisible, editRoom }: Object) =>
  state.merge({
    isRoomFormVisible,
    editRoom,
    errors: []
  })


export const createStreamRequest = (state: Object) =>
  state.merge({
    requestingStream: true,
    errors: [],
  })

export const createStreamSuccess = (state: Object, { roomStream }: Object) => 
  state.merge({
    room: { ...state.room, room_streams: [...state.room.room_streams, roomStream]},
    requestingStream: false,
  })

export const createStreamFailure = (state: Object, { errors }: Object) =>
  state.merge({
    requestingStream: false,
    errors,
  })

export const requestStreamInfoRequest = (state: Object) =>
  state.merge({
    requestingStreamInfo: true,
    errors: [],
  })

export const requestStreamInfoSuccess = (state: Object, { roomStream }: Object) => 
  state.merge({
    roomStreamInfo: roomStream,
    requestingStreamInfo: false,
  })

export const requestStreamInfoFailure = (state: Object, { errors }: Object) =>
  state.merge({
    requestingStreamInfo: false,
    roomStreamInfo: null,
    errors,
  })

export const destroyStreamRequest = (state: Object) =>
  state.merge({
    destroyingStream: true,
    errors: [],
  })

export const destroyStreamSuccess = (state: Object, { roomStream }: Object) => 
  state.merge({
    room: { ...state.room, room_streams: state.room.room_streams.filter(rs => rs.id !== roomStream.id)},
    destroyingStream: false,
  })

export const destroyStreamFailure = (state: Object, { errors }: Object) =>
  state.merge({
    destroyingStream: false,
    errors,
  })

export const clearRoom = (state: Object) =>
  INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ROOMS_REQUEST]: getRoomsRequest,
  [Types.GET_ROOMS_SUCCESS]: getRoomsSuccess,
  [Types.GET_ROOMS_FAILURE]: getRoomsFailure,

  [Types.GET_ROOM_REQUEST]: getRoomRequest,
  [Types.GET_ROOM_SUCCESS]: getRoomSuccess,
  [Types.GET_ROOM_FAILURE]: getRoomFailure,

  [Types.CREATE_ROOM_REQUEST]: createRoomRequest,
  [Types.CREATE_ROOM_SUCCESS]: createRoomSuccess,
  [Types.CREATE_ROOM_FAILURE]: createRoomFailure,

  [Types.UPDATE_ROOM_REQUEST]: updateRoomRequest,
  [Types.UPDATE_ROOM_SUCCESS]: updateRoomSuccess,
  [Types.UPDATE_ROOM_FAILURE]: updateRoomFailure,

  [Types.DESTROY_ROOM_REQUEST]: destroyRoomRequest,
  [Types.DESTROY_ROOM_SUCCESS]: destroyRoomSuccess,
  [Types.DESTROY_ROOM_FAILURE]: destroyRoomFailure,

  [Types.TOGGLE_ROOM_FORM]: toggleRoomForm,
  [Types.CLEAR_ROOM]: clearRoom,

  [Types.REQUEST_STREAM_INFO_REQUEST]: requestStreamInfoRequest,
  [Types.REQUEST_STREAM_INFO_SUCCESS]: requestStreamInfoSuccess,
  [Types.REQUEST_STREAM_INFO_FAILURE]: requestStreamInfoFailure,

  [Types.CREATE_STREAM_REQUEST]: createStreamRequest,
  [Types.CREATE_STREAM_SUCCESS]: createStreamSuccess,
  [Types.CREATE_STREAM_FAILURE]: createStreamFailure,

  [Types.DESTROY_STREAM_REQUEST]: destroyStreamRequest,
  [Types.DESTROY_STREAM_SUCCESS]: destroyStreamSuccess,
  [Types.DESTROY_STREAM_FAILURE]: destroyStreamFailure,
})