/* eslint-disable no-param-reassign */

import { combineReducers } from "redux";
import { resettableReducer } from "reduxsauce";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import configureStore from "./createStore";
import rootSaga from "../sagas";
import { reducer as NavReducer } from "./NavigationRedux";
import { reducer as UserAccountReducer } from "./UserAccountRedux";
import { reducer as AdminAccountReducer } from "./AdminAccountRedux";
import { reducer as CompanyReducer } from "./CompanyRedux";
import { reducer as UserReducer } from "./UserRedux";
import { reducer as BranchReducer } from "./BranchRedux";
import { reducer as RoomReducer } from "./RoomRedux";
import { reducer as MemorialReducer } from "./MemorialRedux";
import { reducer as CityReducer } from "./CityRedux";
import { reducer as CountryReducer } from "./CountryRedux";
import { reducer as ReligionReducer } from "./ReligionRedux";
import { reducer as PublicControllerReducer } from "./PublicControllerRedux";
import { reducer as TypeOfBurialReducer } from "./TypeOfBurialRedux";
import { reducer as BackgroundImageReducer } from "./BackgroundImageRedux";
import { reducer as WhatsappAccountReducer } from "./WhatsappAccountRedux";
import { reducer as ScheduleMessageRuleReducer } from "./ScheduleMessageRuleRedux";

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: []
};

const publicControllerPersistConfig = {
  key: "publicController",
  storage,
  whitelist: ["user"]
};

const resettable = resettableReducer("RESET");

const appReducer = combineReducers({
  nav: resettable(NavReducer),
  userAccount: resettable(UserAccountReducer),
  adminAccount: resettable(AdminAccountReducer),
  company: resettable(CompanyReducer),
  user: resettable(UserReducer),
  branch: resettable(BranchReducer),
  room: resettable(RoomReducer),
  memorial: resettable(MemorialReducer),
  city: resettable(CityReducer),
  country: resettable(CountryReducer),
  religion: resettable(ReligionReducer),
  publicController: persistReducer(
    publicControllerPersistConfig,
    resettable(PublicControllerReducer)
  ),
  typeOfBurial: resettable(TypeOfBurialReducer),
  backgroundImage: resettable(BackgroundImageReducer),
  whatsappAccount: resettable(WhatsappAccountReducer),
  scheduleMessageRule: resettable(ScheduleMessageRuleReducer)
});

const rootReducer = (state, action) => {
  /*if (action.type === "USER_LOGOUT") {
    storage.removeItem("persist:root");
    state = undefined;
  }*/

  return appReducer(state, action);
};

const store = configureStore(
  persistReducer(rootPersistConfig, rootReducer),
  rootSaga
);
const persistor = persistStore(store);

export { store, persistor };
