import { put, call } from "redux-saga/effects";
import PublicControllerActions from "../redux/PublicControllerRedux";
import storage from "redux-persist/lib/storage";

export function* getBranchById(api, action) {
  const { branchId, locale } = action;

  yield call(api.setHeaders, {
    "X-Locale": locale,
  });

  const resp = yield call(api.getBranchById, branchId);

  if (resp.ok) {
    yield put(PublicControllerActions.getBranchByIdSuccess(resp.data));
  } else {
    yield put(PublicControllerActions.getBranchByIdFailure(resp.data.errors));
  }
}

export function* activateScreen(api, action) {
  const { code, locale } = action;

  yield call(api.setHeaders, {
    "X-Locale": locale,
  });

  const resp = yield call(api.activateScreen, code);

  if (resp.ok) {
    storage.setItem("device_fingerprint", resp.data.device_fingerprint);
    yield put(PublicControllerActions.activateScreenSuccess(resp.data));
  } else {
    yield put(PublicControllerActions.activateScreenFailure(resp.data.errors));
  }
}

export function* findRoomDeviceByFingerprint(api, action) {
  const { deviceFingerprint } = action;

  const resp = yield call(api.findRoomDeviceByFingerprint, deviceFingerprint);

  if (resp.ok) {
    yield put(
      PublicControllerActions.findRoomDeviceByFingerprintSuccess(resp.data)
    );
  } else {
    storage.removeItem("device_fingerprint");
    yield put(
      PublicControllerActions.findRoomDeviceByFingerprintFailure(
        resp.data.errors
      )
    );
  }
}

export function* findMemorialByCode(api, action) {
  const { code, locale } = action;

  yield call(api.setHeaders, {
    "X-Locale": locale,
  });

  const resp = yield call(api.findMemorialByCode, code);

  if (resp.ok) {
    yield put(PublicControllerActions.findMemorialByCodeSuccess(resp.data));
  } else {
    yield put(
      PublicControllerActions.findMemorialByCodeFailure(resp.data.errors)
    );
  }
}

export function* createMemorialMessage(api, action) {
  const { code, memorialMessage, locale } = action;

  yield call(api.setHeaders, {
    "X-Locale": locale,
  });

  const resp = yield call(api.createMemorialMessage, code, memorialMessage);

  if (resp.ok) {
    yield put(PublicControllerActions.createMemorialMessageSuccess(resp.data));
  } else {
    yield put(
      PublicControllerActions.createMemorialMessageFailure(resp.data.errors)
    );
  }
}

export function* findMemorialBySlug(api, action) {
  const { slug, token, locale } = action;

  yield call(api.setHeaders, {
    "X-Locale": locale,
  });

  const resp = yield call(api.findMemorialBySlug, slug, token);

  if (resp.ok) {
    yield put(PublicControllerActions.findMemorialBySlugSuccess(resp.data));
  } else {
    yield put(
      PublicControllerActions.findMemorialBySlugFailure(resp.data.errors)
    );
  }
}

export function* removeMemorialMessageWithToken(api, action) {
  const { memorialMessageId, token, locale } = action;

  yield call(api.setHeaders, {
    "X-Locale": locale,
  });

  const resp = yield call(
    api.destroyMemorialMessageWithToken,
    memorialMessageId,
    token
  );

  if (resp.ok) {
    yield put(
      PublicControllerActions.removeMemorialMessageWithTokenSuccess(resp.data)
    );
  } else {
    yield put(
      PublicControllerActions.removeMemorialMessageWithTokenFailure(
        resp.data.errors
      )
    );
  }
}
