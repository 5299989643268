import { createReducer, createActions } from 'reduxsauce'

import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getWhatsappAccountsRequest: [],
  getWhatsappAccountsSuccess: ['whatsappAccounts'],
  getWhatsappAccountsFailure: ['errors'],

  createWhatsappAccountRequest: [],
  createWhatsappAccountSuccess: ['whatsappAccount'],
  createWhatsappAccountFailure: ['errors'],

  updateWhatsappAccountRequest: ['whatsappAccount'],
  updateWhatsappAccountSuccess: ['whatsappAccount'],
  updateWhatsappAccountFailure: ['errors'],

  destroyWhatsappAccountRequest: ['whatsappAccount'],
  destroyWhatsappAccountSuccess: ['whatsappAccount'],
  destroyWhatsappAccountFailure: ['errors'],

  toggleWhatsappAccountForm: ['isWhatsappAccountFormVisible', 'editWhatsappAccount']
})

export const WhatsappAccountTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  whatsappAccount: null,
  errors: [],
  creatingWhatsappAccount: false,
  fetching: false,
  whatsappAccounts: [],
  updatingWhatsappAccount: false,
  isWhatsappAccountFormVisible: false,
  editWhatsappAccount: null,
  destroyingWhatsappAccount: false
})

/* ------------- Reducers ------------- */

export const getWhatsappAccountsRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getWhatsappAccountsSuccess = (state: Object, { whatsappAccounts }: Object) => 
  state.merge({
    whatsappAccounts,
    fetching: false,
    editWhatsappAccount: state.editWhatsappAccount && whatsappAccounts.find(whatsappAccount => whatsappAccount.id === state.editWhatsappAccount.id)
  })

export const getWhatsappAccountsFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const createWhatsappAccountRequest = (state: Object) =>
  state.merge({
    creatingWhatsappAccount: true,
    errors: []  
  })

export const createWhatsappAccountSuccess = (state: Object, { whatsappAccount }: Object) => 
  state.merge({
    whatsappAccounts: [...state.whatsappAccounts, whatsappAccount],
    creatingWhatsappAccount: false,
    isWhatsappAccountFormVisible: false,
    editWhatsappAccount: null
  })

export const createWhatsappAccountFailure = (state: Object, { errors }: Object) =>
  state.merge({
    creatingWhatsappAccount: false,
    errors
  })

export const updateWhatsappAccountRequest = (state: Object) =>
  state.merge({
    updatingWhatsappAccount: true,
    errors: []  
  })

export const updateWhatsappAccountSuccess = (state: Object, { whatsappAccount }: Object) => {
  let whatsappAccounts = state.whatsappAccounts
  
  whatsappAccounts = whatsappAccounts.map((w) => {
    if (w.id === whatsappAccount.id) {
      return whatsappAccount
    }

    return w
  })

  return state.merge({ 
    updatingWhatsappAccount: false,
    isWhatsappAccountFormVisible: false,
    whatsappAccounts,
    editWhatsappAccount: null
  })
}

export const updateWhatsappAccountFailure = (state: Object, { errors }: Object) =>
  state.merge({
    updatingWhatsappAccount: false,
    errors
  })

export const destroyWhatsappAccountRequest = (state: Object) => 
  state.merge({ 
    destroyingWhatsappAccount: true,
    errors: [] 
  })

export const destroyWhatsappAccountSuccess = (state: Object, { whatsappAccount }: Object) =>
  state.merge({ 
    destroyingWhatsappAccount: false,
    whatsappAccounts: state.whatsappAccounts.filter((w) => w.id !== whatsappAccount.id)
  })
  
export const destroyWhatsappAccountFailure = (state: Object, { errors }: Object) =>
  state.merge({
    destroyingWhatsappAccount: false, 
    errors 
  })

export const toggleWhatsappAccountForm = (state: Object, { isWhatsappAccountFormVisible, editWhatsappAccount }: Object) =>
  state.merge({
    isWhatsappAccountFormVisible,
    editWhatsappAccount,
    errors: []
  })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_WHATSAPP_ACCOUNTS_REQUEST]: getWhatsappAccountsRequest,
  [Types.GET_WHATSAPP_ACCOUNTS_SUCCESS]: getWhatsappAccountsSuccess,
  [Types.GET_WHATSAPP_ACCOUNTS_FAILURE]: getWhatsappAccountsFailure,

  [Types.CREATE_WHATSAPP_ACCOUNT_REQUEST]: createWhatsappAccountRequest,
  [Types.CREATE_WHATSAPP_ACCOUNT_SUCCESS]: createWhatsappAccountSuccess,
  [Types.CREATE_WHATSAPP_ACCOUNT_FAILURE]: createWhatsappAccountFailure,

  [Types.UPDATE_WHATSAPP_ACCOUNT_REQUEST]: updateWhatsappAccountRequest,
  [Types.UPDATE_WHATSAPP_ACCOUNT_SUCCESS]: updateWhatsappAccountSuccess,
  [Types.UPDATE_WHATSAPP_ACCOUNT_FAILURE]: updateWhatsappAccountFailure,

  [Types.DESTROY_WHATSAPP_ACCOUNT_REQUEST]: destroyWhatsappAccountRequest,
  [Types.DESTROY_WHATSAPP_ACCOUNT_SUCCESS]: destroyWhatsappAccountSuccess,
  [Types.DESTROY_WHATSAPP_ACCOUNT_FAILURE]: destroyWhatsappAccountFailure,

  [Types.TOGGLE_WHATSAPP_ACCOUNT_FORM]: toggleWhatsappAccountForm
})