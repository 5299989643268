import { createReducer, createActions } from 'reduxsauce'

import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  adminLoginRequest: ['email', 'password'],
  adminLoginSuccess: ['admin'],
  adminLoginFailure: ['errors'],
  adminLoginVerify: ['admin'],
  adminLoginVerifySuccess: ['admin'],
  adminLoginVerifyFailure: ['errors'],
  adminReset: null,
  adminUpdate: ['admin'],
  adminGetMemorialsToPayRequest: ['q'],
  adminGetMemorialsToPaySuccess: ['memorials'],
  adminGetMemorialsToPayFailure: ['errors']
})

export const AdminAccountTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  admin: null,
  errors: [],
  fetching: false,
  memorials: []
})

/* ------------- Reducers ------------- */

export const adminLoginRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const adminLoginSuccess = (state: Object, {admin}: Object) => 
  state.merge({
    admin,
    fetching: false
  })

export const adminLoginFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const adminLoginVerify = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const adminLoginVerifySuccess = (state: Object, { admin }: Object) => 
  state.merge({
    admin,
    fetching: false
  })

export const adminLoginVerifyFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const adminReset = (state: Object) =>
  INITIAL_STATE

export const adminGetMemorialsToPayRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const adminGetMemorialsToPaySuccess = (state: Object, { memorials }: Object) => 
  state.merge({
    memorials,
    fetching: false
  })

export const adminGetMemorialsToPayFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADMIN_LOGIN_REQUEST]: adminLoginRequest,
  [Types.ADMIN_LOGIN_SUCCESS]: adminLoginSuccess,
  [Types.ADMIN_LOGIN_FAILURE]: adminLoginFailure,
  [Types.ADMIN_LOGIN_VERIFY]: adminLoginVerify,
  [Types.ADMIN_LOGIN_VERIFY_SUCCESS]: adminLoginVerifySuccess,
  [Types.ADMIN_LOGIN_VERIFY_FAILURE]: adminLoginVerifyFailure,
  [Types.ADMIN_RESET]: adminReset,

  [Types.ADMIN_GET_MEMORIALS_TO_PAY_REQUEST]: adminGetMemorialsToPayRequest,
  [Types.ADMIN_GET_MEMORIALS_TO_PAY_SUCCESS]: adminGetMemorialsToPaySuccess,
  [Types.ADMIN_GET_MEMORIALS_TO_PAY_FAILURE]: adminGetMemorialsToPayFailure,
})