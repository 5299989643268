import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import lazyRetry from '../util/lazyRetry';
import SuspenseFallback from '../components/Shared/SuspenseFallback';

const UserAppContainer = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'UserAppContainer' */ '../containers/Users/AppContainer')));
const AdminAppContainer = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'AdminAppContainer' */ '../containers/Admins/AppContainer')));
const UserForgotPasswordContainer = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'UserForgotPasswordContainer' */ '../containers/Users/ForgotPasswordContainer')));
const UserPasswordEditContainer = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'UserPasswordEditContainer' */ '../containers/Users/PasswordEditContainer')));
const UserConfirmationContainer = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'UserConfirmationContainer' */ '../containers/Users/ConfirmationContainer')));
const AdminLoginContainer = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'AdminLoginContainer' */ '../containers/Admins/LoginContainer')));
const UserLoginContainer = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'UserLoginContainer' */ '../containers/Users/LoginContainer')));
const ActivateScreenContainer = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'ActivateScreenContainer' */ '../containers/Shared/ActivateScreenContainer')));
const MemorialContainer = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'MemorialContainer' */ '../containers/Shared/MemorialContainer')));
const LeaveMessageContainer = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'LeaveMessageContainer' */ '../containers/Shared/LeaveMessageContainer')));
const LegalComponent = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'LegalComponent' */ '../components/Static/LegalComponent')));
const RefundsComponent = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'RefundsComponent' */ '../components/Static/RefundsComponent')));
const PrivacyComponent = lazy(() => lazyRetry(() => import(/* webpackChunkName: 'PrivacyComponent' */ '../components/Static/PrivacyComponent')));

export default class NavigationRouter extends Component {
  render() {
    return (
      <Router>
        <Suspense fallback={<SuspenseFallback />}>
          <Switch>
            <Route
              exact
              path="/users/forgot"
              component={UserForgotPasswordContainer}
            />
            <Route
              exact
              path="/users/password/edit"
              component={UserPasswordEditContainer}
            />
            <Route
              exact
              path="/users/confirmation"
              component={UserConfirmationContainer}
            />
            <Route exact path="/receiver" component={ActivateScreenContainer} />
            <Route exact path="/memorial/:id" component={MemorialContainer} />
            <Route exact path="/condolencias" component={LeaveMessageContainer} />
            <Route exact path="/admins/login" component={AdminLoginContainer} />
            <Route exact path="/login" component={UserLoginContainer} />

            <Route
              exact
              path="/legal"
              component={LegalComponent}
            />
            <Route
              exact
              path="/refunds"
              component={RefundsComponent}
            />
            <Route
              exact
              path="/privacy"
              component={PrivacyComponent}
            />
            
            <Route path="/admins" component={AdminAppContainer} />
            <Route path="/" component={UserAppContainer} />
          </Switch>
        </Suspense>        
      </Router>
    );
  }
}
