import { createReducer, createActions } from 'reduxsauce'

import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getScheduleMessageRulesRequest: [],
  getScheduleMessageRulesSuccess: ['scheduleMessageRules'],
  getScheduleMessageRulesFailure: ['errors'],

  createScheduleMessageRuleRequest: ['scheduleMessageRule'],
  createScheduleMessageRuleSuccess: ['scheduleMessageRule'],
  createScheduleMessageRuleFailure: ['errors'],

  updateScheduleMessageRuleRequest: ['scheduleMessageRule'],
  updateScheduleMessageRuleSuccess: ['scheduleMessageRule'],
  updateScheduleMessageRuleFailure: ['errors'],

  destroyScheduleMessageRuleRequest: ['scheduleMessageRule'],
  destroyScheduleMessageRuleSuccess: ['scheduleMessageRule'],
  destroyScheduleMessageRuleFailure: ['errors'],

  toggleScheduleMessageRuleForm: ['isScheduleMessageRuleFormVisible', 'editScheduleMessageRule']
})

export const ScheduleMessageRuleTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  scheduleMessageRule: null,
  errors: [],
  creatingScheduleMessageRule: false,
  fetching: false,
  scheduleMessageRules: [],
  updatingScheduleMessageRule: false,
  isScheduleMessageRuleFormVisible: false,
  editScheduleMessageRule: null,
  destroyingScheduleMessageRule: false
})

/* ------------- Reducers ------------- */

export const getScheduleMessageRulesRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getScheduleMessageRulesSuccess = (state: Object, { scheduleMessageRules }: Object) => 
  state.merge({
    scheduleMessageRules,
    fetching: false,
    editScheduleMessageRule: state.editScheduleMessageRule && scheduleMessageRules.find(scheduleMessageRule => scheduleMessageRule.id === state.editScheduleMessageRule.id)
  })

export const getScheduleMessageRulesFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const createScheduleMessageRuleRequest = (state: Object) =>
  state.merge({
    creatingScheduleMessageRule: true,
    errors: []  
  })

export const createScheduleMessageRuleSuccess = (state: Object, { scheduleMessageRule }: Object) => 
  state.merge({
    scheduleMessageRules: [...state.scheduleMessageRules, scheduleMessageRule],
    creatingScheduleMessageRule: false,
    isScheduleMessageRuleFormVisible: false,
    editScheduleMessageRule: null
  })

export const createScheduleMessageRuleFailure = (state: Object, { errors }: Object) =>
  state.merge({
    creatingScheduleMessageRule: false,
    errors
  })

export const updateScheduleMessageRuleRequest = (state: Object) =>
  state.merge({
    updatingScheduleMessageRule: true,
    errors: []  
  })

export const updateScheduleMessageRuleSuccess = (state: Object, { scheduleMessageRule }: Object) => {
  let scheduleMessageRules = state.scheduleMessageRules
  
  scheduleMessageRules = scheduleMessageRules.map((w) => {
    if (w.id === scheduleMessageRule.id) {
      return scheduleMessageRule
    }

    return w
  })

  return state.merge({ 
    updatingScheduleMessageRule: false,
    isScheduleMessageRuleFormVisible: false,
    scheduleMessageRules,
    editScheduleMessageRule: null
  })
}

export const updateScheduleMessageRuleFailure = (state: Object, { errors }: Object) =>
  state.merge({
    updatingScheduleMessageRule: false,
    errors
  })

export const destroyScheduleMessageRuleRequest = (state: Object) => 
  state.merge({ 
    destroyingScheduleMessageRule: true,
    errors: [] 
  })

export const destroyScheduleMessageRuleSuccess = (state: Object, { scheduleMessageRule }: Object) =>
  state.merge({ 
    destroyingScheduleMessageRule: false,
    scheduleMessageRules: state.scheduleMessageRules.filter((w) => w.id !== scheduleMessageRule.id)
  })
  
export const destroyScheduleMessageRuleFailure = (state: Object, { errors }: Object) =>
  state.merge({
    destroyingScheduleMessageRule: false, 
    errors 
  })

export const toggleScheduleMessageRuleForm = (state: Object, { isScheduleMessageRuleFormVisible, editScheduleMessageRule }: Object) =>
  state.merge({
    isScheduleMessageRuleFormVisible,
    editScheduleMessageRule,
    errors: []
  })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SCHEDULE_MESSAGE_RULES_REQUEST]: getScheduleMessageRulesRequest,
  [Types.GET_SCHEDULE_MESSAGE_RULES_SUCCESS]: getScheduleMessageRulesSuccess,
  [Types.GET_SCHEDULE_MESSAGE_RULES_FAILURE]: getScheduleMessageRulesFailure,

  [Types.CREATE_SCHEDULE_MESSAGE_RULE_REQUEST]: createScheduleMessageRuleRequest,
  [Types.CREATE_SCHEDULE_MESSAGE_RULE_SUCCESS]: createScheduleMessageRuleSuccess,
  [Types.CREATE_SCHEDULE_MESSAGE_RULE_FAILURE]: createScheduleMessageRuleFailure,

  [Types.UPDATE_SCHEDULE_MESSAGE_RULE_REQUEST]: updateScheduleMessageRuleRequest,
  [Types.UPDATE_SCHEDULE_MESSAGE_RULE_SUCCESS]: updateScheduleMessageRuleSuccess,
  [Types.UPDATE_SCHEDULE_MESSAGE_RULE_FAILURE]: updateScheduleMessageRuleFailure,

  [Types.DESTROY_SCHEDULE_MESSAGE_RULE_REQUEST]: destroyScheduleMessageRuleRequest,
  [Types.DESTROY_SCHEDULE_MESSAGE_RULE_SUCCESS]: destroyScheduleMessageRuleSuccess,
  [Types.DESTROY_SCHEDULE_MESSAGE_RULE_FAILURE]: destroyScheduleMessageRuleFailure,

  [Types.TOGGLE_SCHEDULE_MESSAGE_RULE_FORM]: toggleScheduleMessageRuleForm
})