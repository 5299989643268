/* eslint-disable no-constant-condition */
import React from 'react';
import { Container, Dimmer, Loader } from 'semantic-ui-react';

// eslint-disable-next-line react/prefer-stateless-function
class SuspenseFallback extends React.Component {
  render() {
    return (
      <div
        style={{
          minHeight: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Container>
          <Dimmer inverted active>
            <Loader />
          </Dimmer>
        </Container>
      </div>
    );
  }
}

export default SuspenseFallback;
