import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt';

import en from './locales/en.json'
import es from './locales/es.json'
import pt from './locales/pt.json'

const resources = {
  en: {
    translation: en
  },
  es: {
    translation: es
  },
  pt: {
    translation: pt
  }
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
  }, (err) => {
    if (err) {
      console.error('Error loading i18n: ', err);
      return;
    }
    const lng = i18n.language.split('-')[0];
    i18n.changeLanguage(lng);
    moment.locale(lng);
  });

export const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
  moment.locale(lng);
}

export default i18n;