import { takeLatest, all } from "redux-saga/effects";
import API from "../services/Api";

/* ------------- Types ------------- */

import { UserAccountTypes } from "../redux/UserAccountRedux";
import { AdminAccountTypes } from "../redux/AdminAccountRedux";
import { CompanyTypes } from "../redux/CompanyRedux";
import { UserTypes } from "../redux/UserRedux";
import { BranchTypes } from "../redux/BranchRedux";
import { MemorialTypes } from "../redux/MemorialRedux";
import { CityTypes } from "../redux/CityRedux";
import { CountryTypes } from "../redux/CountryRedux";
import { RoomTypes } from "../redux/RoomRedux";
import { ReligionTypes } from "../redux/ReligionRedux";
import { PublicControllerTypes } from "../redux/PublicControllerRedux";
import { TypeOfBurialTypes } from "../redux/TypeOfBurialRedux";
import { BackgroundImageTypes } from "../redux/BackgroundImageRedux";
import { WhatsappAccountTypes } from "../redux/WhatsappAccountRedux";

/* ------------- Sagas ------------- */

import {
  userLoginRequest,
  userLoginVerify,
  userConfirm,
  userResetPasswordInstructions,
  userResetPassword,
  userLogout,
  userUpdateCompany,
} from "./UserAccountSagas";
import {
  adminLoginRequest,
  adminLoginVerify,
  adminLogout,
  adminGetMemorialsToPay,
  adminUpdate
} from "./AdminAccountSagas";
import {
  getCompanies,
  createCompany,
  updateCompany,
  destroyCompany,
  getBillingPlans,
  getCompany
} from "./CompanySagas";
import { getUsers, createUser, updateUser, destroyUser } from "./UserSagas";
import {
  getBranches,
  getBranch,
  createBranch,
  updateBranch,
  destroyBranch
} from "./BranchSagas";
import {
  getMemorials,
  getMemorial,
  createMemorial,
  updateMemorial,
  destroyMemorial,
  blockMemorialMessage,
  unblockMemorialMessage,
  destroyMemorialMessage,
  getMemorialsToPay,
} from "./MemorialSagas";
import { getCities } from "./CitySagas";
import { getCountries } from "./CountrySagas";
import {
  getRooms,
  getRoom,
  createRoom,
  updateRoom,
  destroyRoom,
  requestStreamInfoRequest,
  createStreamRequest,
  destroyStreamRequest
} from "./RoomSagas";
import {
  getReligions,
  createReligion,
  updateReligion,
  destroyReligion
} from "./ReligionSagas";
import {
  getBranchById,
  activateScreen,
  findRoomDeviceByFingerprint,
  findMemorialByCode,
  findMemorialBySlug,
  createMemorialMessage,
  removeMemorialMessageWithToken
} from "./PublicControllerSagas";
import { getTypeOfBurials } from "./TypeOfBurialSagas";
import {
  getBackgroundImages,
  createBackgroundImage,
  updateBackgroundImage,
  destroyBackgroundImage
} from "./BackgroundImageSagas";

import { getWhatsappAccounts, createWhatsappAccount, updateWhatsappAccount, destroyWhatsappAccount } from "./WhatsappAccountSagas";
import { getScheduleMessageRules, createScheduleMessageRule, updateScheduleMessageRule, destroyScheduleMessageRule } from "./ScheduleMessageRuleSagas";
import { ScheduleMessageRuleTypes } from "../redux/ScheduleMessageRuleRedux";

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = API.create();

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // some sagas only receive an action
    takeLatest(UserAccountTypes.USER_LOGIN_REQUEST, userLoginRequest, api),
    takeLatest(UserAccountTypes.USER_LOGIN_VERIFY, userLoginVerify, api),
    takeLatest(UserAccountTypes.USER_CONFIRM_REQUEST, userConfirm, api),
    takeLatest(UserAccountTypes.USER_RESET, userLogout, api),
    takeLatest(
      UserAccountTypes.USER_RESET_PASSWORD_INSTRUCTIONS_REQUEST,
      userResetPasswordInstructions,
      api
    ),
    takeLatest(
      UserAccountTypes.USER_RESET_PASSWORD_REQUEST,
      userResetPassword,
      api
    ),
    takeLatest(
      UserAccountTypes.USER_UPDATE_COMPANY_REQUEST,
      userUpdateCompany,
      api
    ),

    takeLatest(AdminAccountTypes.ADMIN_LOGIN_REQUEST, adminLoginRequest, api),
    takeLatest(AdminAccountTypes.ADMIN_LOGIN_VERIFY, adminLoginVerify, api),
    takeLatest(AdminAccountTypes.ADMIN_RESET, adminLogout, api),
    takeLatest(AdminAccountTypes.ADMIN_UPDATE, adminUpdate, api),
    takeLatest(
      AdminAccountTypes.ADMIN_GET_MEMORIALS_TO_PAY_REQUEST,
      adminGetMemorialsToPay,
      api
    ),

    takeLatest(CompanyTypes.GET_COMPANIES_REQUEST, getCompanies, api),
    takeLatest(CompanyTypes.CREATE_COMPANY_REQUEST, createCompany, api),
    takeLatest(CompanyTypes.UPDATE_COMPANY_REQUEST, updateCompany, api),
    takeLatest(CompanyTypes.DESTROY_COMPANY_REQUEST, destroyCompany, api),
    takeLatest(CompanyTypes.GET_BILLING_PLANS_REQUEST, getBillingPlans, api),
    takeLatest(CompanyTypes.GET_COMPANY_REQUEST, getCompany, api),

    takeLatest(UserTypes.GET_USERS_REQUEST, getUsers, api),
    takeLatest(UserTypes.CREATE_USER_REQUEST, createUser, api),
    takeLatest(UserTypes.UPDATE_USER_REQUEST, updateUser, api),
    takeLatest(UserTypes.DESTROY_USER_REQUEST, destroyUser, api),

    takeLatest(BranchTypes.GET_BRANCHES_REQUEST, getBranches, api),
    takeLatest(BranchTypes.GET_BRANCH_REQUEST, getBranch, api),
    takeLatest(BranchTypes.CREATE_BRANCH_REQUEST, createBranch, api),
    takeLatest(BranchTypes.UPDATE_BRANCH_REQUEST, updateBranch, api),
    takeLatest(BranchTypes.DESTROY_BRANCH_REQUEST, destroyBranch, api),

    takeLatest(RoomTypes.GET_ROOMS_REQUEST, getRooms, api),
    takeLatest(RoomTypes.GET_ROOM_REQUEST, getRoom, api),
    takeLatest(RoomTypes.CREATE_ROOM_REQUEST, createRoom, api),
    takeLatest(RoomTypes.UPDATE_ROOM_REQUEST, updateRoom, api),
    takeLatest(RoomTypes.DESTROY_ROOM_REQUEST, destroyRoom, api),
    takeLatest(RoomTypes.REQUEST_STREAM_INFO_REQUEST, requestStreamInfoRequest, api),
    takeLatest(RoomTypes.CREATE_STREAM_REQUEST, createStreamRequest, api),
    takeLatest(RoomTypes.DESTROY_STREAM_REQUEST, destroyStreamRequest, api),

    takeLatest(ReligionTypes.GET_RELIGIONS_REQUEST, getReligions, api),
    takeLatest(ReligionTypes.CREATE_RELIGION_REQUEST, createReligion, api),
    takeLatest(ReligionTypes.UPDATE_RELIGION_REQUEST, updateReligion, api),
    takeLatest(ReligionTypes.DESTROY_RELIGION_REQUEST, destroyReligion, api),

    takeLatest(CityTypes.GET_CITIES_REQUEST, getCities, api),

    takeLatest(CountryTypes.GET_COUNTRIES_REQUEST, getCountries, api),

    takeLatest(
      TypeOfBurialTypes.GET_TYPE_OF_BURIALS_REQUEST,
      getTypeOfBurials,
      api
    ),

    takeLatest(
      BackgroundImageTypes.GET_BACKGROUND_IMAGES_REQUEST,
      getBackgroundImages,
      api
    ),
    takeLatest(
      BackgroundImageTypes.CREATE_BACKGROUND_IMAGE_REQUEST,
      createBackgroundImage,
      api
    ),
    takeLatest(
      BackgroundImageTypes.UPDATE_BACKGROUND_IMAGE_REQUEST,
      updateBackgroundImage,
      api
    ),
    takeLatest(
      BackgroundImageTypes.DESTROY_BACKGROUND_IMAGE_REQUEST,
      destroyBackgroundImage,
      api
    ),

    takeLatest(MemorialTypes.GET_MEMORIALS_REQUEST, getMemorials, api),
    takeLatest(MemorialTypes.GET_MEMORIAL_REQUEST, getMemorial, api),
    takeLatest(MemorialTypes.CREATE_MEMORIAL_REQUEST, createMemorial, api),
    takeLatest(MemorialTypes.UPDATE_MEMORIAL_REQUEST, updateMemorial, api),
    takeLatest(MemorialTypes.DESTROY_MEMORIAL_REQUEST, destroyMemorial, api),
    takeLatest(
      MemorialTypes.BLOCK_MEMORIAL_MESSAGE_REQUEST,
      blockMemorialMessage,
      api
    ),
    takeLatest(
      MemorialTypes.UNBLOCK_MEMORIAL_MESSAGE_REQUEST,
      unblockMemorialMessage,
      api
    ),
    takeLatest(
      MemorialTypes.DESTROY_MEMORIAL_MESSAGE_REQUEST,
      destroyMemorialMessage,
      api
    ),
    takeLatest(
      MemorialTypes.GET_MEMORIALS_TO_PAY_REQUEST,
      getMemorialsToPay,
      api
    ),

    takeLatest(
      PublicControllerTypes.GET_BRANCH_BY_ID_REQUEST,
      getBranchById,
      api
    ),
    takeLatest(
      PublicControllerTypes.ACTIVATE_SCREEN_REQUEST,
      activateScreen,
      api
    ),
    takeLatest(
      PublicControllerTypes.FIND_ROOM_DEVICE_BY_FINGERPRINT_REQUEST,
      findRoomDeviceByFingerprint,
      api
    ),
    takeLatest(
      PublicControllerTypes.FIND_MEMORIAL_BY_CODE_REQUEST,
      findMemorialByCode,
      api
    ),
    takeLatest(
      PublicControllerTypes.CREATE_MEMORIAL_MESSAGE_REQUEST,
      createMemorialMessage,
      api
    ),
    takeLatest(
      PublicControllerTypes.FIND_MEMORIAL_BY_SLUG_REQUEST,
      findMemorialBySlug,
      api
    ),
    takeLatest(
      PublicControllerTypes.REMOVE_MEMORIAL_MESSAGE_WITH_TOKEN_REQUEST,
      removeMemorialMessageWithToken,
      api
    ),

    takeLatest(WhatsappAccountTypes.GET_WHATSAPP_ACCOUNTS_REQUEST, getWhatsappAccounts, api),
    takeLatest(WhatsappAccountTypes.CREATE_WHATSAPP_ACCOUNT_REQUEST, createWhatsappAccount, api),
    takeLatest(WhatsappAccountTypes.UPDATE_WHATSAPP_ACCOUNT_REQUEST, updateWhatsappAccount, api),
    takeLatest(WhatsappAccountTypes.DESTROY_WHATSAPP_ACCOUNT_REQUEST, destroyWhatsappAccount, api),

    takeLatest(ScheduleMessageRuleTypes.GET_SCHEDULE_MESSAGE_RULES_REQUEST, getScheduleMessageRules, api),
    takeLatest(ScheduleMessageRuleTypes.CREATE_SCHEDULE_MESSAGE_RULE_REQUEST, createScheduleMessageRule, api),
    takeLatest(ScheduleMessageRuleTypes.UPDATE_SCHEDULE_MESSAGE_RULE_REQUEST, updateScheduleMessageRule, api),
    takeLatest(ScheduleMessageRuleTypes.DESTROY_SCHEDULE_MESSAGE_RULE_REQUEST, destroyScheduleMessageRule, api),
  ]);
}
