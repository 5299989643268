import { put, call } from 'redux-saga/effects'
import MemorialActions from '../redux/MemorialRedux'

export function * getMemorials (api, action) {
  const { q } = action
  const resp = yield call(api.getMemorials, q)

  if (resp.ok) {
    yield put(MemorialActions.getMemorialsSuccess(resp.data))
  } else {
    yield put(MemorialActions.getMemorialsFailure(resp.data))
  }
}

export function * getMemorial (api, action) {
  const { memorialId } = action

  const resp = yield call(api.getMemorial, memorialId)

  if (resp.ok) {
    yield put(MemorialActions.getMemorialSuccess(resp.data))
  } else {
    yield put(MemorialActions.getMemorialFailure(resp.data))
  }
}

export function * createMemorial (api, action) {
  const { memorial } = action

  const resp = yield call(api.createMemorial, memorial)

  if (resp.ok) {
    yield put(MemorialActions.createMemorialSuccess(resp.data))
  } else {
    yield put(MemorialActions.createMemorialFailure(resp.data))
  }
}

export function * updateMemorial (api, action) {
  const { memorial } = action

  const resp = yield call(api.updateMemorial, memorial)

  if (resp.ok) {
    yield put(MemorialActions.updateMemorialSuccess(resp.data))
  } else {
    yield put(MemorialActions.updateMemorialFailure(resp.data))
  }
}

export function * destroyMemorial (api, action) {
  const { memorial } = action

  const resp = yield call(api.destroyMemorial, memorial)

  if (resp.ok) {
    yield put(MemorialActions.destroyMemorialSuccess(resp.data))
  } else {
    yield put(MemorialActions.destroyMemorialFailure(resp.data))
  }
}

export function * blockMemorialMessage (api, action) {
  const { memorialMessage } = action

  const resp = yield call(api.blockMemorialMessage, memorialMessage)

  if (resp.ok) {
    yield put(MemorialActions.blockMemorialMessageSuccess(resp.data))
  } else {
    yield put(MemorialActions.blockMemorialMessageFailure(resp.data))
  }
}

export function * unblockMemorialMessage (api, action) {
  const { memorialMessage } = action

  const resp = yield call(api.unblockMemorialMessage, memorialMessage)

  if (resp.ok) {
    yield put(MemorialActions.unblockMemorialMessageSuccess(resp.data))
  } else {
    yield put(MemorialActions.unblockMemorialMessageFailure(resp.data))
  }
}

export function * destroyMemorialMessage (api, action) {
  const { memorialMessage } = action

  const resp = yield call(api.destroyMemorialMessage, memorialMessage)

  if (resp.ok) {
    yield put(MemorialActions.destroyMemorialMessageSuccess(resp.data))
  } else {
    yield put(MemorialActions.destroyMemorialMessageFailure(resp.data))
  }
}

export function * getMemorialsToPay (api, action) {
  const { q } = action
  const resp = yield call(api.getMemorialsToPay, q)

  if (resp.ok) {
    yield put(MemorialActions.getMemorialsToPaySuccess(resp.data))
  } else {
    yield put(MemorialActions.getMemorialsToPayFailure(resp.data))
  }
}