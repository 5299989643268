// a library to wrap and simplify api calls
import apisauce from "apisauce";
import $ from "jquery";
import moment from "moment";


const config = require("../config");
const node_env = process.env.NODE_ENV || "development";

const apiUrl = config[node_env]["apiUrl"].replace("https:", window.location.protocol);

const appendData = (formData, keys, value) => {
  if (value instanceof File || value instanceof Blob) {
    // If the value is a File, append it as is
    formData.append(`${keys[0]}[${keys.slice(1).join('][')}]`, value);
  } else if (value instanceof moment) {
    // If the value is a moment, append it as a formatted string
    formData.append(`${keys[0]}[${keys.slice(1).join('][')}]`, value.format());
  } else if (value !== null && typeof value === 'object') {
    // Recursive call if value itself is an object
    Object.keys(value).forEach(subKey => {
      appendData(formData, [...keys, subKey], value[subKey]);
    });
  } else {
    // Join keys with square brackets to form 'parent[child]' or 'parent[child][grandchild]'
    formData.append(`${keys[0]}[${keys.slice(1).join('][')}]`, value || '');
  }
};

// our "constructor"
const create = (baseURL = apiUrl) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const api = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache"
    },
    timeout: 60000
  });

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //
  const setHeaders = headers => api.setHeaders(headers);

  const userLogin = (email, password) =>
    api.post("/users/sign_in.json", { user: { email, password } });
  const userVerifyLogin = () => api.get("/users/verify.json");
  const userConfirm = confirmationToken =>
    api.get("/users/confirmation.json", {
      confirmation_token: confirmationToken
    });
  const userResetPasswordInstructions = email =>
    api.post("/users/password.json", { user: { email } });
  const userResetPassword = user => api.put("/users/password.json", { user });
  const adminLogin = (email, password) =>
    api.post("/admins/sign_in.json", { admin: { email, password } });
  const adminVerifyLogin = () => api.get("/admin/admins/verify.json");
  
  const adminGetMemorialsToPay = query => {
    query.q.starts_at_gteq = query.q.starts_at_gteq.format("YYYY-MM-DD HH:mm");
    query.q.starts_at_lteq = query.q.starts_at_lteq.format("YYYY-MM-DD HH:mm");

    return api.get("/admin/memorials/to_pay.json?" + $.param(query));
  };
  const adminUpdate = (admin) => api.put(`/admin/admins/${admin.id}.json`, { admin });

  // Admin routes
  const getCompanies = () => api.get("/admin/companies.json");
  const createCompany = company => {
    const data = new FormData();

    Object.keys(company).forEach(key => {
      appendData(data, ['company', key], company[key]);
    });

    return api.post("/admin/companies.json", data);
  };
  const updateCompany = company => {
    const data = new FormData();

    Object.keys(company).forEach(key => {
      appendData(data, ['company', key], company[key]);
    });

    return api.put("/admin/companies/" + company.id + ".json", data);
  };
  const destroyCompany = company =>
    api.delete("/admin/companies/" + company.id + ".json");
  const getBillingPlans = () => api.get("/admin/billing_plans.json");
  const getCompany = companyId => api.get(`/admin/companies/${companyId}.json`);

  const getReligions = () => api.get("/religions.json");
  const createReligion = religion => {
    const data = new FormData();

    Object.keys(religion).forEach(key => {
      appendData(data, ['religion', key], religion[key]);
    });

    return api.post("/admin/religions.json", data);
  };
  const updateReligion = religion => {
    const data = new FormData();

    Object.keys(religion).forEach(key => {
      appendData(data, ['religion', key], religion[key]);
    });

    return api.put("/admin/religions/" + religion.id + ".json", data);
  };
  const destroyReligion = religion =>
    api.delete("/admin/religions/" + religion.id + ".json");

  const getBackgroundImages = () => api.get("/background_images.json");
  const createBackgroundImage = backgroundImage => {
    const data = new FormData();

    Object.keys(backgroundImage).forEach(key => {
      appendData(data, ['background_image', key], backgroundImage[key]);
    });

    return api.post("/background_images.json", data);
  };
  const updateBackgroundImage = backgroundImage => {
    const data = new FormData();

    Object.keys(backgroundImage).forEach(key => {
      appendData(data, ['background_image', key], backgroundImage[key]);
    });

    return api.put(
      "/background_images/" + backgroundImage.id + ".json",
      data
    );
  };
  const destroyBackgroundImage = backgroundImage =>
    api.delete("/background_images/" + backgroundImage.id + ".json");

  // User routes
  const getUsers = () => api.get("/users.json");
  const createUser = user => api.post("/users.json", { user });
  const updateUser = user => api.put("/users/" + user.id + ".json", { user });
  const destroyUser = user => api.delete("/users/" + user.id + ".json");

  const getBranches = () => api.get("/branches.json");
  const getBranch = branchId => api.get(`/branches/${branchId}.json`);
  const createBranch = branch => api.post("/branches.json", { branch });
  const updateBranch = branch =>
    api.put("/branches/" + branch.id + ".json", { branch });
  const destroyBranch = branch =>
    api.delete("/branches/" + branch.id + ".json");

  const getRooms = branchId => api.get(`/branches/${branchId}/rooms.json`);
  const createRoom = room =>
    api.post(`/branches/${room.branch_id}/rooms.json`, { room });
  const getRoom = roomId => api.get(`/rooms/${roomId}.json`);
  const updateRoom = room => api.put("/rooms/" + room.id + ".json", { room });
  const destroyRoom = room => api.delete("/rooms/" + room.id + ".json");
  const requestStreamInfo = roomStreamId =>
    api.get("/room_streams/" + roomStreamId + ".json");
  const createStream = roomId =>
    api.post("/rooms/" + roomId + "/room_streams.json");
  const destroyStream = roomStreamId =>
    api.delete("/room_streams/" + roomStreamId + ".json");

  const getMemorials = query => {
    query.q.starts_at_gteq = query.q.starts_at_gteq.format("YYYY-MM-DD HH:mm");
    query.q.starts_at_lteq = query.q.starts_at_lteq.format("YYYY-MM-DD HH:mm");

    return api.get("/memorials.json?" + $.param(query));
  };

  const getMemorial = memorialId => api.get(`/memorials/${memorialId}.json`);
  const createMemorial = memorial => {
    const data = new FormData();

    Object.keys(memorial).forEach(key => {
      appendData(data, ['memorial', key], memorial[key]);
    });

    return api.post("/memorials.json", data);
  };
  const updateMemorial = memorial => {
    const data = new FormData();

    Object.keys(memorial).forEach(key => {
      appendData(data, ['memorial', key], memorial[key]);
    });

    return api.put("/memorials/" + memorial.id + ".json", data);
  };
  const destroyMemorial = memorial =>
    api.delete("/memorials/" + memorial.id + ".json");
  const getMemorialsToPay = query => {
    query.q.starts_at_gteq = query.q.starts_at_gteq.format("YYYY-MM-DD HH:mm");
    query.q.starts_at_lteq = query.q.starts_at_lteq.format("YYYY-MM-DD HH:mm");

    return api.get("/memorials/to_pay.json?" + $.param(query));
  };

  const blockMemorialMessage = memorialMessage =>
    api.post("/memorial_messages/" + memorialMessage.id + "/block.json");
  const unblockMemorialMessage = memorialMessage =>
    api.post("/memorial_messages/" + memorialMessage.id + "/unblock.json");
  const destroyMemorialMessage = memorialMessage =>
    api.delete("/memorial_messages/" + memorialMessage.id + ".json");

  const getCities = countryId =>
    api.get("/states.json?q[country_id_eq]=" + countryId);
  const getTypeOfBurials = () => api.get("/type_of_burials.json");
  const getCountries = () => api.get("/admin/countries.json");

  const activateScreen = (code, device_fingerprint) =>
    api.post("/activate.json", { code, device_fingerprint });
  const findRoomDeviceByFingerprint = device_fingerprint =>
    api.get("/find_room_device_by_fingerprint.json", { device_fingerprint });
  const getBranchById = branchId =>
    api.get("/find_branch_by_id.json", { id: branchId });
  const findMemorialByCode = code =>
    api.get(`/find_memorial_by_code.json?code=${code}`);
  const findMemorialBySlug = (slug, token) =>
    api.get(`/memorial/${slug}.json?token=${token}`);
  const createMemorialMessage = (code, memorial_message) => {
    const data = new FormData();

    Object.keys(memorial_message).forEach(key => {
      appendData(data, ['memorial_message', key], memorial_message[key]);
    });

    return api.post(`/create_memorial_message.json?code=${code}`, data);
  };
  const destroyMemorialMessageWithToken = (memorialMessageId, token) =>
    api.delete("/destroy_memorial_message.json", {
      id: memorialMessageId,
      token: token
    });

  const userUpdateCompany = company => {
    const data = new FormData();

    Object.keys(company).forEach(key => {
      appendData(data, ['company', key], company[key]);
    });

    return api.put("/companies/" + company.id + ".json", data);
  };

  const getWhatsappAccounts = () => api.get("/whatsapp_accounts.json");
  const createWhatsappAccount = () => api.post("/whatsapp_accounts.json");
  const updateWhatsappAccount = whatsappAccount => api.put("/whatsapp_accounts/" + whatsappAccount.id + ".json", { whatsappAccount });
  const destroyWhatsappAccount = whatsappAccount => api.delete("/whatsapp_accounts/" + whatsappAccount.id + ".json");

  const getScheduleMessageRules = () => api.get("/schedule_message_rules.json");
  const createScheduleMessageRule = schedule_message_rule => api.post("/schedule_message_rules.json", { schedule_message_rule });
  const updateScheduleMessageRule = schedule_message_rule => api.put("/schedule_message_rules/" + schedule_message_rule.id + ".json", { schedule_message_rule });
  const destroyScheduleMessageRule = schedule_message_rule => api.delete("/schedule_message_rules/" + schedule_message_rule.id + ".json");

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    setHeaders,
    userLogin,
    userVerifyLogin,
    userConfirm,
    userResetPasswordInstructions,
    userResetPassword,
    adminLogin,
    adminVerifyLogin,
    adminGetMemorialsToPay,
    adminUpdate,

    getCompanies,
    createCompany,
    updateCompany,
    destroyCompany,
    getBillingPlans,
    getCompany,

    getReligions,
    createReligion,
    updateReligion,
    destroyReligion,

    getBackgroundImages,
    createBackgroundImage,
    updateBackgroundImage,
    destroyBackgroundImage,

    getUsers,
    createUser,
    updateUser,
    destroyUser,

    getBranches,
    getBranch,
    createBranch,
    updateBranch,
    destroyBranch,

    getRooms,
    getRoom,
    createRoom,
    updateRoom,
    destroyRoom,
    requestStreamInfo,
    createStream,
    destroyStream,

    getMemorials,
    getMemorial,
    createMemorial,
    updateMemorial,
    destroyMemorial,
    getMemorialsToPay,
    blockMemorialMessage,
    unblockMemorialMessage,
    destroyMemorialMessage,

    getCities,

    getTypeOfBurials,

    getCountries,

    getBranchById,
    activateScreen,
    findRoomDeviceByFingerprint,
    findMemorialByCode,
    findMemorialBySlug,
    createMemorialMessage,
    destroyMemorialMessageWithToken,

    userUpdateCompany,

    getWhatsappAccounts,
    createWhatsappAccount,
    updateWhatsappAccount,
    destroyWhatsappAccount,

    getScheduleMessageRules,
    createScheduleMessageRule,
    updateScheduleMessageRule,
    destroyScheduleMessageRule
  };
};

// let's return back our create method as the default.
export default {
  create
};
