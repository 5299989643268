import { put, call } from 'redux-saga/effects'
import RoomActions from '../redux/RoomRedux'

export function * getRooms (api, action) {
  const { branchId } = action

  const resp = yield call(api.getRooms, branchId)

  if (resp.ok) {
    yield put(RoomActions.getRoomsSuccess(resp.data))
  } else {
    yield put(RoomActions.getRoomsFailure(resp.data))
  }
}

export function * getRoom (api, action) {
  const { roomId } = action

  const resp = yield call(api.getRoom, roomId)

  if (resp.ok) {
    yield put(RoomActions.getRoomSuccess(resp.data))
  } else {
    yield put(RoomActions.getRoomFailure(resp.data))
  }
}

export function * createRoom (api, action) {
  const { room } = action

  const resp = yield call(api.createRoom, room)

  if (resp.ok) {
    yield put(RoomActions.createRoomSuccess(resp.data))
  } else {
    yield put(RoomActions.createRoomFailure(resp.data))
  }
}

export function * updateRoom (api, action) {
  const { room } = action

  const resp = yield call(api.updateRoom, room)

  if (resp.ok) {
    yield put(RoomActions.updateRoomSuccess(resp.data))
  } else {
    yield put(RoomActions.updateRoomFailure(resp.data))
  }
}

export function * destroyRoom (api, action) {
  const { room } = action

  const resp = yield call(api.destroyRoom, room)

  if (resp.ok) {
    yield put(RoomActions.destroyRoomSuccess(resp.data))
  } else {
    yield put(RoomActions.destroyRoomFailure(resp.data))
  }
}

export function * requestStreamInfoRequest (api, action) {
  const { roomStreamId } = action
  const resp = yield call(api.requestStreamInfo, roomStreamId)

  if (resp.ok) {
    yield put(RoomActions.requestStreamInfoSuccess(resp.data))
  } else {
    yield put(RoomActions.requestStreamInfoFailure(resp.data))
  }
}

export function * createStreamRequest (api, action) {
  const { roomId } = action
  const resp = yield call(api.createStream, roomId)

  if (resp.ok) {
    yield put(RoomActions.createStreamSuccess(resp.data))
  } else {
    yield put(RoomActions.createStreamFailure(resp.data))
  }
}

export function * destroyStreamRequest (api, action) {
  const { roomStreamId } = action
  const resp = yield call(api.destroyStream, roomStreamId)

  if (resp.ok) {
    yield put(RoomActions.destroyStreamSuccess(resp.data))
  } else {
    yield put(RoomActions.destroyStreamFailure(resp.data))
  }
}