import { createReducer, createActions } from "reduxsauce";

import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  activateScreenRequest: ["code", "locale"],
  activateScreenSuccess: ["roomDevice"],
  activateScreenFailure: ["errors"],

  findRoomDeviceByFingerprintRequest: ["deviceFingerprint"],
  findRoomDeviceByFingerprintSuccess: ["roomDevice"],
  findRoomDeviceByFingerprintFailure: ["errors"],

  getBranchByIdRequest: ["branchId", "locale"],
  getBranchByIdSuccess: ["branch"],
  getBranchByIdFailure: ["errors"],

  findMemorialByCodeRequest: ["code", "locale"],
  findMemorialByCodeSuccess: ["memorial"],
  findMemorialByCodeFailure: ["errors"],

  createMemorialMessageRequest: ["code", "memorialMessage", "locale"],
  createMemorialMessageSuccess: ["memorialMessage"],
  createMemorialMessageFailure: ["errors"],

  findMemorialBySlugRequest: ["slug", "token", "locale"],
  findMemorialBySlugSuccess: ["memorial"],
  findMemorialBySlugFailure: ["errors"],

  removeMemorialMessageWithTokenRequest: ["memorialMessageId", "token", "locale"],
  removeMemorialMessageWithTokenSuccess: ["memorialMessage"],
  removeMemorialMessageWithTokenFailure: ["errors"],

  setUser: ["user"],
  clearUser: null,
  clearMemorial: null
});

export const PublicControllerTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  branch: null,
  roomDevice: null,
  errors: [],
  fetching: false,
  activatingScreen: false,
  memorial: null,
  creatingMessage: false,
  memorialMessage: null,
  destroyingMessage: false,
  user: null
});

/* ------------- Reducers ------------- */

export const activateScreenRequest = (state: Object) => ({
  ...state,
  activatingScreen: true,
  errors: []
});

export const activateScreenSuccess = (
  state: Object,
  { roomDevice }: Object
) => ({
  ...state,
  roomDevice,
  activatingScreen: false
});

export const activateScreenFailure = (state: Object, { errors }: Object) => ({
  ...state,
  activatingScreen: false,
  errors
});

export const findRoomDeviceByFingerprintRequest = (state: Object) => ({
  ...state,
  fetching: true,
  errors: []
});

export const findRoomDeviceByFingerprintSuccess = (
  state: Object,
  { roomDevice }: Object
) => ({
  ...state,
  roomDevice,
  fetching: false
});

export const findRoomDeviceByFingerprintFailure = (
  state: Object,
  { errors }: Object
) => ({
  ...state,
  fetching: false,
  errors
});

export const getBranchByIdRequest = (state: Object) => ({
  ...state,
  fetching: true,
  errors: []
});

export const getBranchByIdSuccess = (state: Object, { branch }: Object) => ({
  ...state,
  branch,
  fetching: false
});

export const getBranchByIdFailure = (state: Object, { errors }: Object) => ({
  ...state,
  fetching: false,
  errors
});

export const findMemorialByCodeRequest = (state: Object) => ({
  ...state,
  fetching: true,
  errors: []
});

export const findMemorialByCodeSuccess = (
  state: Object,
  { memorial }: Object
) => ({
  ...state,
  memorial,
  fetching: false
});

export const findMemorialByCodeFailure = (
  state: Object,
  { errors }: Object
) => ({
  ...state,
  fetching: false,
  errors
});

export const createMemorialMessageRequest = (state: Object) => ({
  ...state,
  creatingMessage: true,
  errors: [],
  memorialMessage: null
});

export const createMemorialMessageSuccess = (
  state: Object,
  { memorialMessage }
) => ({
  ...state,
  creatingMessage: false,
  memorialMessage
});

export const createMemorialMessageFailure = (
  state: Object,
  { errors }: Object
) => ({
  ...state,
  creatingMessage: false,
  errors
});

export const findMemorialBySlugRequest = (state: Object) => ({
  ...state,
  fetching: true,
  errors: []
});

export const findMemorialBySlugSuccess = (
  state: Object,
  { memorial }: Object
) => ({
  ...state,
  memorial,
  fetching: false
});

export const findMemorialBySlugFailure = (
  state: Object,
  { errors }: Object
) => ({
  ...state,
  fetching: false,
  errors
});

export const removeMemorialMessageWithTokenRequest = (state: Object) => ({
  ...state,
  destroyingMessage: true,
  errors: [],
  memorialMessage: null
});

export const removeMemorialMessageWithTokenSuccess = (
  state: Object,
  { memorialMessage }: Object
) => ({
  ...state,
  memorialMessage,
  destroyingMessage: false
});

export const removeMemorialMessageWithTokenFailure = (
  state: Object,
  { errors }: Object
) => ({
  ...state,
  destroyingMessage: false,
  errors
});

export const setUser = (state: Object, { user }: Object) => ({
  ...state,
  user
});

export const clearUser = (state: Object) => ({
  ...state,
  user: null
});

export const clearMemorial = (state: Object) => ({
  ...state,
  memorial: null
});

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ACTIVATE_SCREEN_REQUEST]: activateScreenRequest,
  [Types.ACTIVATE_SCREEN_SUCCESS]: activateScreenSuccess,
  [Types.ACTIVATE_SCREEN_FAILURE]: activateScreenFailure,

  [Types.FIND_ROOM_DEVICE_BY_FINGERPRINT_REQUEST]: findRoomDeviceByFingerprintRequest,
  [Types.FIND_ROOM_DEVICE_BY_FINGERPRINT_SUCCESS]: findRoomDeviceByFingerprintSuccess,
  [Types.FIND_ROOM_DEVICE_BY_FINGERPRINT_FAILURE]: findRoomDeviceByFingerprintFailure,

  [Types.GET_BRANCH_BY_ID_REQUEST]: getBranchByIdRequest,
  [Types.GET_BRANCH_BY_ID_SUCCESS]: getBranchByIdSuccess,
  [Types.GET_BRANCH_BY_ID_FAILURE]: getBranchByIdFailure,

  [Types.FIND_MEMORIAL_BY_CODE_REQUEST]: findMemorialByCodeRequest,
  [Types.FIND_MEMORIAL_BY_CODE_SUCCESS]: findMemorialByCodeSuccess,
  [Types.FIND_MEMORIAL_BY_CODE_FAILURE]: findMemorialByCodeFailure,

  [Types.CREATE_MEMORIAL_MESSAGE_REQUEST]: createMemorialMessageRequest,
  [Types.CREATE_MEMORIAL_MESSAGE_SUCCESS]: createMemorialMessageSuccess,
  [Types.CREATE_MEMORIAL_MESSAGE_FAILURE]: createMemorialMessageFailure,

  [Types.FIND_MEMORIAL_BY_SLUG_REQUEST]: findMemorialBySlugRequest,
  [Types.FIND_MEMORIAL_BY_SLUG_SUCCESS]: findMemorialBySlugSuccess,
  [Types.FIND_MEMORIAL_BY_SLUG_FAILURE]: findMemorialBySlugFailure,

  [Types.REMOVE_MEMORIAL_MESSAGE_WITH_TOKEN_REQUEST]: removeMemorialMessageWithTokenRequest,
  [Types.REMOVE_MEMORIAL_MESSAGE_WITH_TOKEN_SUCCESS]: removeMemorialMessageWithTokenSuccess,
  [Types.REMOVE_MEMORIAL_MESSAGE_WITH_TOKEN_FAILURE]: removeMemorialMessageWithTokenFailure,

  [Types.SET_USER]: setUser,
  [Types.CLEAR_USER]: clearUser,
  [Types.CLEAR_MEMORIAL]: clearMemorial
});
