import { put, call } from 'redux-saga/effects'
import WhatsappAccountActions from '../redux/WhatsappAccountRedux'

export function * getWhatsappAccounts (api) {
  const resp = yield call(api.getWhatsappAccounts)

  if (resp.ok) {
    yield put(WhatsappAccountActions.getWhatsappAccountsSuccess(resp.data))
  } else {
    yield put(WhatsappAccountActions.getWhatsappAccountsFailure(resp.data))
  }
}

export function * createWhatsappAccount (api) {
  const resp = yield call(api.createWhatsappAccount)

  if (resp.ok) {
    yield put(WhatsappAccountActions.createWhatsappAccountSuccess(resp.data))
  } else {
    yield put(WhatsappAccountActions.createWhatsappAccountFailure(resp.data))
  }
}

export function * updateWhatsappAccount (api, action) {
  const { whatsappAccount } = action

  const resp = yield call(api.updateWhatsappAccount, whatsappAccount)

  if (resp.ok) {
    yield put(WhatsappAccountActions.updateWhatsappAccountSuccess(resp.data))
  } else {
    yield put(WhatsappAccountActions.updateWhatsappAccountFailure(resp.data))
  }
}

export function * destroyWhatsappAccount (api, action) {
  const { whatsappAccount } = action

  const resp = yield call(api.destroyWhatsappAccount, whatsappAccount)

  if (resp.ok) {
    yield put(WhatsappAccountActions.destroyWhatsappAccountSuccess(resp.data))
  } else {
    yield put(WhatsappAccountActions.destroyWhatsappAccountFailure(resp.data))
  }
}