import { createReducer, createActions } from 'reduxsauce'

import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getMemorialsRequest: ['q'],
  getMemorialsSuccess: ['memorials'],
  getMemorialsFailure: ['errors'],

  getMemorialRequest: ['memorialId'],
  getMemorialSuccess: ['memorial'],
  getMemorialFailure: ['errors'],

  createMemorialRequest: ['memorial'],
  createMemorialSuccess: ['memorial'],
  createMemorialFailure: ['errors'],

  updateMemorialRequest: ['memorial'],
  updateMemorialSuccess: ['memorial'],
  updateMemorialFailure: ['errors'],

  destroyMemorialRequest: ['memorial'],
  destroyMemorialSuccess: ['memorial'],
  destroyMemorialFailure: ['errors'],

  blockMemorialMessageRequest: ['memorialMessage'],
  blockMemorialMessageSuccess: ['memorialMessage'],
  blockMemorialMessageFailure: ['errors'],

  unblockMemorialMessageRequest: ['memorialMessage'],
  unblockMemorialMessageSuccess: ['memorialMessage'],
  unblockMemorialMessageFailure: ['errors'],

  destroyMemorialMessageRequest: ['memorialMessage'],
  destroyMemorialMessageSuccess: ['memorialMessage'],
  destroyMemorialMessageFailure: ['errors'],

  toggleMemorialForm: ['isMemorialFormVisible', 'editMemorial'],

  getMemorialsToPayRequest: ['q'],
  getMemorialsToPaySuccess: ['memorials'],
  getMemorialsToPayFailure: ['errors'],
})

export const MemorialTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  memorial: null,
  errors: [],
  creatingMemorial: false,
  fetching: false,
  memorials: [],
  updatingMemorial: false,
  isMemorialFormVisible: false,
  editMemorial: null,
  destroyingMemorial: false,
  memorialMessage: null,
  blockingMemorialMessage: false,
  unblockingMemorialMessage: false,
  destroyingMemorialMessage: false,
})

/* ------------- Reducers ------------- */

export const getMemorialsRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getMemorialsSuccess = (state: Object, { memorials }: Object) => 
  state.merge({
    memorials,
    fetching: false
  })

export const getMemorialsFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const getMemorialRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getMemorialSuccess = (state: Object, { memorial }: Object) => 
  state.merge({
    memorial,
    fetching: false
  })

export const getMemorialFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

export const createMemorialRequest = (state: Object) =>
  state.merge({
    creatingMemorial: true,
    errors: []  
  })

export const createMemorialSuccess = (state: Object, { memorial }: Object) => 
  state.merge({
    memorials: [...state.memorials, memorial],
    creatingMemorial: false,
    isMemorialFormVisible: false,
    editMemorial: null
  })

export const createMemorialFailure = (state: Object, { errors }: Object) =>
  state.merge({
    creatingMemorial: false,
    errors
  })

export const updateMemorialRequest = (state: Object) =>
  state.merge({
    updatingMemorial: true,
    errors: []  
  })

export const updateMemorialSuccess = (state: Object, { memorial }: Object) => {
  let memorials = state.memorials
  
  memorials = memorials.map((w) => {
    if (w.id === memorial.id) {
      return memorial
    }

    return w
  })

  return state.merge({ 
    updatingMemorial: false,
    isMemorialFormVisible: false,
    memorials,
    editMemorial: null
  })
}

export const updateMemorialFailure = (state: Object, { errors }: Object) =>
  state.merge({
    updatingMemorial: false,
    errors
  })

export const destroyMemorialRequest = (state: Object) => 
  state.merge({ 
    destroyingMemorial: true,
    errors: [] 
  })

export const destroyMemorialSuccess = (state: Object, { memorial }: Object) =>
  state.merge({ 
    destroyingMemorial: false,
    memorials: state.memorials.filter((w) => w.id !== memorial.id)
  })
  
export const destroyMemorialFailure = (state: Object, { errors }: Object) =>
  state.merge({
    destroyingMemorial: false, 
    errors 
  })

export const toggleMemorialForm = (state: Object, { isMemorialFormVisible, editMemorial }: Object) => {
  let memorial = isMemorialFormVisible ? editMemorial : undefined

  return state.merge({
    isMemorialFormVisible,
    editMemorial: memorial,
    errors: []
  })
}

export const blockMemorialMessageRequest = (state: Object) => 
  state.merge({ 
    blockingMemorialMessage: true,
    errors: [] 
  })

export const blockMemorialMessageSuccess = (state: Object, { memorialMessage }: Object) =>
  state.merge({ 
    blockingMemorialMessage: false,
    memorial: {...state.memorial, memorial_messages: state.memorial.memorial_messages.map((m) => {
      if (m.id === memorialMessage.id) {
        return memorialMessage
      }

      return m
    })}
  })
  
export const blockMemorialMessageFailure = (state: Object, { errors }: Object) =>
  state.merge({
    blockingMemorialMessage: false, 
    errors 
  })

export const unblockMemorialMessageRequest = (state: Object) => 
  state.merge({ 
    unblockingMemorialMessage: true,
    errors: [] 
  })

export const unblockMemorialMessageSuccess = (state: Object, { memorialMessage }: Object) =>
  state.merge({ 
    unblockingMemorialMessage: false,
    memorial: {...state.memorial, memorial_messages: state.memorial.memorial_messages.map((m) => {
      if (m.id === memorialMessage.id) {
        return memorialMessage
      }

      return m
    })}
  })
  
export const unblockMemorialMessageFailure = (state: Object, { errors }: Object) =>
  state.merge({
    unblockingMemorialMessage: false, 
    errors 
  })

export const destroyMemorialMessageRequest = (state: Object) => 
  state.merge({ 
    destroyingMemorialMessage: true,
    errors: [] 
  })

export const destroyMemorialMessageSuccess = (state: Object, { memorialMessage }: Object) =>
  state.merge({ 
    destroyingMemorialMessage: false,
    memorial: {...state.memorial, memorial_messages: state.memorial.memorial_messages.filter((m) => m.id !== memorialMessage.id) }
  })
  
export const destroyMemorialMessageFailure = (state: Object, { errors }: Object) =>
  state.merge({
    destroyingMemorialMessage: false, 
    errors 
  })

export const getMemorialsToPayRequest = (state: Object) =>
  state.merge({
    fetching: true,
    errors: []  
  })

export const getMemorialsToPaySuccess = (state: Object, { memorials }: Object) => 
  state.merge({
    memorials,
    fetching: false
  })

export const getMemorialsToPayFailure = (state: Object, { errors }: Object) =>
  state.merge({
    fetching: false,
    errors
  })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_MEMORIALS_REQUEST]: getMemorialsRequest,
  [Types.GET_MEMORIALS_SUCCESS]: getMemorialsSuccess,
  [Types.GET_MEMORIALS_FAILURE]: getMemorialsFailure,

  [Types.GET_MEMORIAL_REQUEST]: getMemorialRequest,
  [Types.GET_MEMORIAL_SUCCESS]: getMemorialSuccess,
  [Types.GET_MEMORIAL_FAILURE]: getMemorialFailure,

  [Types.CREATE_MEMORIAL_REQUEST]: createMemorialRequest,
  [Types.CREATE_MEMORIAL_SUCCESS]: createMemorialSuccess,
  [Types.CREATE_MEMORIAL_FAILURE]: createMemorialFailure,

  [Types.UPDATE_MEMORIAL_REQUEST]: updateMemorialRequest,
  [Types.UPDATE_MEMORIAL_SUCCESS]: updateMemorialSuccess,
  [Types.UPDATE_MEMORIAL_FAILURE]: updateMemorialFailure,

  [Types.DESTROY_MEMORIAL_REQUEST]: destroyMemorialRequest,
  [Types.DESTROY_MEMORIAL_SUCCESS]: destroyMemorialSuccess,
  [Types.DESTROY_MEMORIAL_FAILURE]: destroyMemorialFailure,

  [Types.BLOCK_MEMORIAL_MESSAGE_REQUEST]: blockMemorialMessageRequest,
  [Types.BLOCK_MEMORIAL_MESSAGE_SUCCESS]: blockMemorialMessageSuccess,
  [Types.BLOCK_MEMORIAL_MESSAGE_FAILURE]: blockMemorialMessageFailure,

  [Types.UNBLOCK_MEMORIAL_MESSAGE_REQUEST]: unblockMemorialMessageRequest,
  [Types.UNBLOCK_MEMORIAL_MESSAGE_SUCCESS]: unblockMemorialMessageSuccess,
  [Types.UNBLOCK_MEMORIAL_MESSAGE_FAILURE]: unblockMemorialMessageFailure,

  [Types.DESTROY_MEMORIAL_MESSAGE_REQUEST]: destroyMemorialMessageRequest,
  [Types.DESTROY_MEMORIAL_MESSAGE_SUCCESS]: destroyMemorialMessageSuccess,
  [Types.DESTROY_MEMORIAL_MESSAGE_FAILURE]: destroyMemorialMessageFailure,

  [Types.TOGGLE_MEMORIAL_FORM]: toggleMemorialForm,

  [Types.GET_MEMORIALS_TO_PAY_REQUEST]: getMemorialsToPayRequest,
  [Types.GET_MEMORIALS_TO_PAY_SUCCESS]: getMemorialsToPaySuccess,
  [Types.GET_MEMORIALS_TO_PAY_FAILURE]: getMemorialsToPayFailure,
})